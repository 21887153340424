.loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; /* Ensure it's above other content */
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5); /* semi-transparent white background */
  z-index: 1; /* Ensures the loader is above other content */
}

.loader-hidden {
    display: none;
}