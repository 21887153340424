.error-modal {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.error-content {
  background-color: rgba(114, 5, 14, 0.5); /* Red background with 50% transparency */
  /* opacity: 1.0; 50% transparency */
  color: #ffffff;
  padding: 10px 20px;
  border-left: 6px solid #d8000c; /* Red accent line */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-message {
  flex: 1;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}
